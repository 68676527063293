export default [
  {
    header: 'e-Coupon',
    resource: 'Coupon',
    action: 'read',
  },
  {
    title: 'Coupon',
    route: 'ecoupon-coupon-list',
    icon: 'CreditCardIcon',
    resource: 'Coupon',
    action: 'read',
  },
  {
    title: 'Redeem',
    route: 'redeem-list',
    icon: 'CheckIcon',
    resource: 'Redeem',
    action: 'read',
  },
  {
    title: 'Refund',
    route: 'refund-list',
    icon: 'XIcon',
    resource: 'Refund',
    action: 'read',
  },
  {
    title: 'Brand',
    route: 'ecoupon-brand-list',
    icon: 'BriefcaseIcon',
    resource: 'Brand',
    action: 'read',
  },
  {
    title: 'Merchant',
    route: 'ecoupon-merchant-list',
    icon: 'ShoppingBagIcon',
    resource: 'Merchant',
    action: 'read',
  },
]
