<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span
            class="brand-logo"
            style="margin-top: -0.35rem;"
          >
            <one-day-black
              v-if="(skin === 'light')"
            />
            <one-day-white v-else />
          </span>
          <h2 class="brand-text mb-0">
            {{ appName }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import OneDayBlack from '@/@core/layouts/components/OneDayBlack.vue'
import OneDayWhite from '@/@core/layouts/components/OneDayWhite.vue'
import store from '@/store'

export default {
  components: {
    BLink,
    OneDayBlack,
    OneDayWhite,
  },
  data() {
    return {
      skin: store.state.appConfig.layout.skin,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
